import React, { useState } from 'react'
import styled from 'styled-components';

import { Link } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';




/* max-width:1200px; */
/* margin: 0 auto !important;  */
/* float: none !important;  */




const NavLinks = styled.div`
width:100svw;
display: flex;
max-width:1300px;
justify-content: space-evenly;
color: #3a84ff;
background-color: #fff;
position: sticky;
top: 0;
left:0;
z-index:9;
align-self:center;

@media (max-width: 426px) {
width:80%;
height:100dvh;
z-index:9;
flex-direction:column;
justify-content: center;
align-items: flex-start;
position: fixed;
top:0;
left: unset;
right:0;
color: #fff;
background-color: #ef8a5f;
/* box-sizing: 0 40px 60px rgba(0,0,0,0.1); */
transition: 0.4s ease-in-out;
}

`;
const NavLinkMobile = styled.h1`
display: none;

@media (max-width: 426px){
   display: block;
   margin-inline: auto;
font-size: 1.3rem;
margin: 1rem 3rem;

text-transform: uppercase;
}
`;
const NavLink = styled.h1`
margin-inline: auto;
font-size: 1.3rem;
text-transform: uppercase;
transition: 0.3s ease-in-out;
position: relative;

@media (max-width: 426px){
    margin: 1rem 3rem;
}
&:hover{
    color: #26a695;
}
&:hover::after{
    background: #26a695;
    content: "";
    width: 2em;
    height:2.5px;
    position: absolute;
    bottom:-4px;
    left: 0px;
}
`;

const Menu = styled.div`
z-index:11;
margin: 1rem;
color: #3a84ff;
position: relative;

@media (min-width: 426px) {
display: none;
}
`;

const Button = styled.div`
padding: 10px 20px;
border-radius:20px;
border: none;
color:#fff;
font-weight:600;
font-size:1.2rem;
background-color: #26a695;
cursor: pointer;

`;







const Navbar = () => {

    const [isActive,setIsActive] = useState(false)

  return (
    <div >
            <Menu onClick={()=>{setIsActive(!isActive)}}>
            {!isActive ?         
            <MenuIcon/>
            :
            <CloseIcon/>
            }
            </Menu>
        <NavLinks 
        style={!isActive ?{top:"-100px",right:"-80%"}:{right:"0",top:"0"}}
         >
        <Link to="/echipa" onClick={()=>{setIsActive(!isActive)}} style={{ textDecoration:"none", color:"inherit"}}>
            <NavLink>ECHIPA MEDICALĂ</NavLink>
        </Link>
        <Link to="/preturi" onClick={()=>{setIsActive(!isActive)}} style={{textDecoration:"none", color:"inherit"}}>
            <NavLink>SERVICII ȘI TARIFE</NavLink>
        </Link>
        <Link to="/optica" onClick={()=>{setIsActive(!isActive)}} style={{textDecoration:"none", color:"inherit"}}>
            <NavLink>Magazin Optica</NavLink>
        </Link>
        <Link to="/contact" onClick={()=>{setIsActive(!isActive)}} style={{textDecoration:"none", color:"inherit"}}>
            <NavLinkMobile>Contact</NavLinkMobile>
        </Link>
        {/* <Link to="/servicii" onClick={()=>{setIsActive(!isActive)}} style={{textDecoration:"none", color:"inherit"}}>
            <NavLink>Servicii</NavLink>
        </Link> */}
        <Link to="/programare" onClick={()=>{setIsActive(!isActive)}} style={{ alignSelf:"center", textDecoration:"none", color:"inherit"}}>
            <Button>Programează-te</Button>
        </Link>

        </NavLinks>
    </div>
  )
}

export default Navbar