import React from 'react'
import Navbar from './Navbar'
import Topbar from './Topbar'
import styled from 'styled-components'
import { Link } from 'react-router-dom';

import LogoPediatrie from '../img/oftalmopediatrie_logo.png'

const Container = styled.div`
width: 100%;
display:flex;
flex-direction: column;
@media (max-width: 426px) {
    align-items:flex-start;
    flex-direction: row;
}
`;

const Wrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
position: relative;

@media (max-width: 426px){

    width:100%;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    /* background-color: red; */
}
`;

const Logo = styled.img`
aspect-ratio: auto 1809 / 633;
height:80px;
margin-top: 25px;

@media (max-width: 426px) {
    height:50px;
    margin-top: 15px;
    margin-left: 15px;
}

`;

const Header = () => {
  return (<Container>

    <Topbar/>
    <Wrapper>
        <Link to="/" style={{textDecoration:"none", color:"inherit"}}>
            <Logo src={LogoPediatrie}/>
        </Link> 
        <Navbar/>   
    </Wrapper>
  </Container>

  )
}

export default Header