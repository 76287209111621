import React from 'react'
import styled from 'styled-components'
// import Poza from 'https://ophtamax.ro/wp-content/uploads/2021/12/Aparatura_Ophtamax034.jpg'

const Container = styled.div`
display: flex;
flex-direction:column;
width:100%;
`;
const Section = styled.div`
display:flex;
align-items: center;
height: 90svh;
margin-block:20px;
&:nth-of-type(even){
    flex-direction: row-reverse;    
    @media (max-width: 826px){ 
        flex-direction:column;
        width:100%;        
        height: 100%;
    }
}
@media (max-width: 826px){ 
    flex-direction: column;
    width:100%;
    height: 100%;
}
`;

const LeftSide = styled.div`
width:40%;

@media (max-width: 826px){ 
    width:100%;
}
`;

const RightSide = styled.div`
display: flex;
flex-direction: column;
width:60%;
@media (max-width: 826px){ 
    width:80%;
    margin:auto;
}
`;

const RightSideWrapper = styled.div`
width:80%;
margin: auto;
@media (max-width: 826px){ 
    width:100%;
}

`;
const PhotoWrapper = styled.div`
@media (max-width: 826px){ 
height:50svh;
}
`;

const Photo = styled.div`
height:90svh;
background-size: cover;
background-position: center center;

@media (max-width: 826px){ 
    /* position: relative; */
    width:100%;
    height:100%;
}

`;

const TitleWrapper = styled.h2`
margin-bottom: 65px;
@media (max-width: 826px){ 
    margin-bottom: 45px;
}
`;
const Title = styled.h2`
font-weight:300;
font-size: clamp(1.625rem, 1.5vw + 1rem, 2.25rem);
line-height: clamp(1.78rem, 1.5vw + 1rem, 2.5rem);
margin-bottom: 20px;
color: #26a695;
`;
const SubTitle = styled.h4`
color: #26a695;
font-size: clamp(1.238rem, 0.5vw + 1rem, 1.375rem);
margin:0 0 10px 0;
`;
const ParagraphWrapper = styled.div`
display: flex;
width:100%;
justify-content:space-between;
@media (max-width: 826px){ 
flex-direction: column;
}

`;
const ParagraphColumn = styled.div`
width:45%;
@media (max-width: 826px){ 
    width:100%;
}
`;
const ParagraphTitle = styled.h4`
font-size: clamp(1.125rem, 2vw + 1rem, 1.375rem);
margin:0 0 10px 0;
color: #2298cb;
`;
const Paragraph = styled.div`
font-size: 20px;
color: #2298cb;
line-height: 36px;
margin-bottom:20px;
`;

const DisplaySection = () => {

    const intersectionCallback = (entries) => {
        for (const entry of entries) { // Loop over all elements that either enter or exit the view.
          if (entry.isIntersecting) { // This is true when the element is in view.
            entry.target.classList.add('show'); // Add a class.
          }
        }
      }      
      /**
       * Create a observer and use the instersectionCallback as 
       * the instructions for what to do when an element enters
       * or leaves the view
       */
      const observer = new IntersectionObserver(intersectionCallback);
      
      /**
       * Get all .item elements and loop over them.
       * Observe each individual item.
       */
      const items = document.querySelectorAll('.animation');
      for (const item of items) {
        observer.observe(item);
      }
  return (
    <Container>
        <Section id="section-1">
          <LeftSide>
            <PhotoWrapper>
              <Photo className="animation" style={{backgroundImage:`url('https://ophtamax.ro/wp-content/uploads/2021/12/Aparatura_Ophtamax034.jpg')`}} src='https://ophtamax.ro/wp-content/uploads/2021/12/Aparatura_Ophtamax034.jpg'/>
            </PhotoWrapper>
          </LeftSide>
          <RightSide>
            <RightSideWrapper>
            <TitleWrapper>
            <Title><b>RAME</b> PENTRU OCHELARI</Title>
            <SubTitle>Din plastic sau metal, clasice sau moderne, noi te sfătuim, tu alegi!</SubTitle>
            </TitleWrapper>
            <ParagraphWrapper>
                <ParagraphColumn>
            <ParagraphTitle>Consiliere</ParagraphTitle>
            <Paragraph>Specialiștii OphtaMax te vor consilia în alegerea ramei perfecte, țînând cont de forma feței, stilul de viață, stilul vestimentar (business, classic, modern, sport), dioptriile prescrise, buget alocat.
                        </Paragraph>
                    <Paragraph>
                        Vino în magazinul de optică și descoperă rama care se potrivește cel mai bine stilului tău.</Paragraph>
                </ParagraphColumn>
                <ParagraphColumn>
            <ParagraphTitle>Branduri de top</ParagraphTitle>
            <Paragraph>Dolce&Gabbana, Silhouette, Chariol, Line Art, Vogue, Ray Ban, Carolina Herrera, Ana Hickmann, Furla, Guess, Calvin Klein, Hugo Boss, Roberto Cavalli, Just Cavalli, Emporio Armani, Sferoflex, Police, Sover, Clark, Ocean Titan, Ocean, Oliver, Rhein.</Paragraph>
                </ParagraphColumn>
            </ParagraphWrapper>
            </RightSideWrapper>
          </RightSide>
        </Section>
        <Section>
          <LeftSide>
            <PhotoWrapper>
              <Photo className="animation" style={{backgroundImage:`url('https://ophtamax.ro/wp-content/uploads/2021/12/Aparatura_Ophtamax028.jpg')`}} src="https://ophtamax.ro/wp-content/uploads/2021/12/Aparatura_Ophtamax028.jpg"/>
            </PhotoWrapper>
          </LeftSide>
          <RightSide>
            <RightSideWrapper>

            <TitleWrapper>
            <Title>OCHELARI DE <b>SOARE</b></Title>
            <SubTitle>Completează-ți stilul cu o pereche de ochelari de soare!</SubTitle>
            </TitleWrapper>
            <ParagraphWrapper>
                <ParagraphColumn>
            <ParagraphTitle>Protecție UV</ParagraphTitle>
            <Paragraph>Ochelarii nu sunt doar un accesoriu fashion ci și un mijloc de protecție al ochilor față de radiațiile solare UV. Cei care oferă protecție maximă, sunt cei cu lentile polarizate.</Paragraph>
            <Paragraph>Poți alege dintr-o varietate de modele și brand-uri.</Paragraph>
                </ParagraphColumn>
                <ParagraphColumn>
                    <Paragraph>Aceștia sunt recomandați în special persoanelor care conduc și celor care petrec foarte mult timp în spații deschise, acolo unde razele sunt reflectate și intensificate de zăpadă, apă sau suprafețe plane.</Paragraph>
                    <Paragraph>Ray Ban, Dolce&Gabbana, Vogue, Polaroid, BenX, Timberland, Nautica, Arnette.</Paragraph>
                </ParagraphColumn>
            </ParagraphWrapper>
            </RightSideWrapper>
          </RightSide>
        </Section>
        <Section>
          <LeftSide>
            <PhotoWrapper>
              <Photo className="animation" style={{backgroundImage:`url('https://ophtamax.ro/wp-content/uploads/2021/12/Aparatura_Ophtamax017-1.jpg')`}} src='https://ophtamax.ro/wp-content/uploads/2021/12/Aparatura_Ophtamax017-1.jpg' />
            </PhotoWrapper>
          </LeftSide>
          <RightSide>
          <RightSideWrapper>

            <TitleWrapper>
            <Title><b>LENTILE</b> DE OCHELARI</Title>
            <SubTitle>În funcție de stilul de viață, tipul lentilelor are un rol important.</SubTitle>
            </TitleWrapper>
            <ParagraphWrapper>
                <ParagraphColumn>
                    <ParagraphTitle>Tehnologie modernă</ParagraphTitle>
                    <Paragraph>Realizate cu ajutorul înaltei tehnologii și adaptate nevoilor individuale, pentru a oferi o experiență vizuala de excepție, lentilele de ochelari, îmbunătățesc stilul de viață al purtătorului.</Paragraph>
                </ParagraphColumn>
                <ParagraphColumn>
                    <ParagraphTitle>Consiliere</ParagraphTitle>
                    <Paragraph>Monofocale , bifocale, progresive sau degresive (pentru lucrul la birou), la noi vei fi îndrumat întotdeauna să-ți alegi perechea care ți se potrivește, în funcţie de necesitate.</Paragraph>
                </ParagraphColumn>
            </ParagraphWrapper>
                <Paragraph>Produse inovative, de înaltă claritate, concepute pe baza unei cercetări îndelungate, lentilele progresive pot fi personalizate cu ajutorul măsurătorilor speciale realizate cu aparatul Visioffice 3, aparat de ultimă generație unic în județul Prahova.</Paragraph>
            </RightSideWrapper>
          </RightSide>
        </Section>
        <Section>
          <LeftSide>
            <PhotoWrapper>
              <Photo className="animation" style={{backgroundImage:`url('https://ophtamax.ro/wp-content/uploads/2021/12/Aparatura_Ophtamax012-1.jpg')`}} src='https://ophtamax.ro/wp-content/uploads/2021/12/Aparatura_Ophtamax012-1.jpg'/>
            </PhotoWrapper>
          </LeftSide>
          <RightSide>
            <RightSideWrapper>
            <TitleWrapper>
            <Title>MONTAJ ÎN <b>LABORATORUL PROPRIU</b></Title>
            <SubTitle>Cel mai bun montaj pentru ochelării tăi.</SubTitle>
            </TitleWrapper>
            <ParagraphWrapper>                
                <Paragraph>Montajul de înalta calitate, datorita experienței tehnicianului optometrist și a aparaturii de ultimă generație alaturi de consilierea clientului, în privința purtării și întreținerii lentilelor și ramelor, vor oferi întotdeauna satisfacție maximă celor care aleg să monteze ochelarii în laboratorul nostru.</Paragraph>
            </ParagraphWrapper>
            </RightSideWrapper>
          </RightSide>
        </Section>
        <Section>
          <LeftSide>
            <PhotoWrapper>
              <Photo className="animation" style={{backgroundImage:`url('https://ophtamax.ro/wp-content/uploads/2021/12/case-for-contact-lenses-and-liquid-on-grey-table-2021-09-02-21-41-27-utc.jpg')`}} src='https://ophtamax.ro/wp-content/uploads/2021/12/case-for-contact-lenses-and-liquid-on-grey-table-2021-09-02-21-41-27-utc.jpg'/>
            </PhotoWrapper>
          </LeftSide>
          <RightSide>
            <RightSideWrapper>
            <TitleWrapper>
            <Title><b>LENTILE DE CONTACT</b> ȘI SOLUȚII OFTALMICE</Title>
            <SubTitle>Alternativa modernă de corecție a vederii</SubTitle>
            </TitleWrapper>
            <ParagraphWrapper>
                <ParagraphColumn>
                    <Paragraph>Purtarea lentilelor de contact este de cele mai multe ori preferată pentru că nu limitează câmpul vizual. Acestea pot fi purtate și de pacienții cu dioptrii mari, de cei care practică sportul, sau de ce nu, de cei care vor să-și schimbe culoarea ochilor.</Paragraph>
                </ParagraphColumn>
                <ParagraphColumn>
                    <Paragraph>La noi găsești următoarele tipuri de lentile: Monofocale sferice, Monofocale torice, multifocale, cosmetice, protetice sau pentru ortokeratologie de la producători de top cum ar fi: Alcon, Cooper Vision, Johnson & Johnson, Bausch & Lomb, Menicon.</Paragraph>
                </ParagraphColumn>
            </ParagraphWrapper>
            <Paragraph>Specialiștii OphtaMax te vor consilia în privința purtării și întreținerii lentilelor și ramelor, pentru a-ți oferi satisfacție maximă.</Paragraph>
            </RightSideWrapper>
          </RightSide>
        </Section>
        <Section>
          <LeftSide>
            <PhotoWrapper>
              <Photo className="animation" style={{backgroundImage:`url('https://ophtamax.ro/wp-content/uploads/2021/12/Aparatura_Ophtamax020.jpg')`}} src='https://ophtamax.ro/wp-content/uploads/2021/12/Aparatura_Ophtamax020.jpg'/>
            </PhotoWrapper>
          </LeftSide>
          <RightSide>
            <RightSideWrapper>
            <TitleWrapper>
            <Title><b>SERVICE</b></Title>
            <SubTitle>Garanția calității, nu trebuie să coste niciodată!</SubTitle>
            </TitleWrapper>
            <ParagraphWrapper>                
                <Paragraph>Oferim asistență service gratuit clienților opticii noastre și îi sfătuim să ne viziteze periodic pentru verificarea șuruburilor, balamalelor, schimbarea pernuțelor, pentru ca ochelarul să ofere comfort cât mai mult timp.</Paragraph>
            </ParagraphWrapper>
            </RightSideWrapper>
          </RightSide>
        </Section>
        <Section>
          <LeftSide>
            <PhotoWrapper>
              <Photo className="animation" style={{backgroundImage:`url('https://ophtamax.ro/wp-content/uploads/2021/12/Aparatura_Ophtamax022.jpg')`}} src='https://ophtamax.ro/wp-content/uploads/2021/12/Aparatura_Ophtamax022.jpg'/>
            </PhotoWrapper>
          </LeftSide>
          <RightSide>
            <RightSideWrapper>
            <TitleWrapper>
            <Title><b>ACCESORII </b>PENTRU OCHELARI</Title>
            <SubTitle>Personalizează-ți ochelarii cu stil!</SubTitle>
            </TitleWrapper>
            <ParagraphWrapper>                
                <Paragraph>Vă punem la dispoziție o gamă largă de accesorii, de la șnururi, lavete și tocuri până la soluții de curățare sau antiaburire pentru întreținerea lentilelor.</Paragraph>
            </ParagraphWrapper>
            </RightSideWrapper>
          </RightSide>
        </Section>
    </Container>
  )
}

export default DisplaySection