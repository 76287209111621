import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import AboutImg from '../../img/about_img.jpg'
import styled from 'styled-components'

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const Continer = styled.div`
display:flex;
margin-top: 20px;
`;

const Wrapper = styled.div`
display: flex;
max-width: 1300px;
margin: auto;
@media (max-width: 426px){

flex-direction: column;
gap:20px;

}
`;

const PhotoWrapper = styled.div`
position: relative;
width:fit-content;
@media (max-width: 826px){
    display: flex;
    flex-direction: column;
    aspect-ratio: auto;
    height:45dvh;
    width:100%;
    
}
`;
const Photo = styled.div`
height:75dvh;
aspect-ratio: 1/1;
background-size: cover;
position: relative;
/* background-color: lightgray; */
@media (max-width: 826px){
aspect-ratio: auto;
height:45dvh;
}
`;


const RightArrow = styled.div`
cursor: pointer;
position: absolute;
top:50%;
right:1%;
z-index:10;
svg {
    height: 80px;
    width: 80px;
    color:#fff;
}
`;

const LeftArrow = styled.div`
cursor: pointer;
position: absolute;
top:50%;
left:1%;
z-index:10;
svg {
    /* font-weight:800; */
    height: 80px;
    width: 80px;
    color:#fff;
}
`;

const TextWrapper = styled.div`
display:flex;
flex-direction: column;
justify-content: space-evenly;
align-items: center;
gap: 20px;
`;

const Text = styled.div`
width:80%;
color: #3a84ff;
font-weight: 300;
font-size:1.3rem;
/* text-indent:5%; */
font-style: italic;
@media (max-width: 426px){
    font-size:1.2rem;
    /* text-indent:15px; */
    width:80%;
}
`;

const Button = styled.div`
padding: 10px 20px;
border-radius:20px;
border: none;
color:#fff;
font-weight:600;
font-size:1.2rem;
background-color: #26a695;
cursor: pointer;
`;

const AboutUs = () => {
    const [currentIndex,setCurrentIndex] = useState(0)

const nextPhoto = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex+1;
    setCurrentIndex(newIndex);    
}
const previosPhoto = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length -1 : currentIndex-1;
    setCurrentIndex(newIndex);    
}

const slides = [
    {url: "https://ophtamax.ro/wp-content/uploads/2021/12/Oana-Andrei-Medic-Primar-Oftalmolog.jpg", title: "Oana Andrei"},
    {url: "https://ophtamax.ro/wp-content/uploads/2021/12/Andreea-Dancescu-Gheorghe-Medic-Specialist-Oftalmolog.jpg", title: "Andreea Dancescu"},
    {url: "https://ophtamax.ro/wp-content/uploads/2021/12/Irina-Velcea-Medic-Primar-Oftalmolog.jpg", title: "Irina Velcea"},
]

const photoStyle = {
    backgroundImage: `url(${slides[currentIndex].url})`,
};
  return (
    <Continer>
        <Wrapper>
            <PhotoWrapper>
                <LeftArrow onClick={previosPhoto}><KeyboardArrowLeftIcon/></LeftArrow>
                <Photo style={photoStyle} />
                <Link to="/echipa"  style={{ textDecoration:"none", color:"inherit"}}>
                <div style={{width:'60%',  position:'absolute', top:'0', left: '20%', zIndex:1000,height:'100%', margin:'auto'}}></div>
                </Link>
                <RightArrow onClick={nextPhoto}><KeyboardArrowRightIcon/></RightArrow>
            </PhotoWrapper>
            <TextWrapper>
                {/* <Text><b>Clinica de oftalmopediatrie Ophtamax</b> ofera consultatii complete de oftalmologie copiilor de toate varstele cu ajutorul echipei medicale formata din <b>Dr. Oana Andrei, Dr. Andreea Dancescu, Dr. Alina Moroianu si Dr. Velcea Irina</b>  medici oftalmologi cu experienta in oftalmopediatrie.</Text> */}
                <Text><b>Echipa noastră medicală</b> este formată doar din medici cu competențe și abilități reale în oftalmopediatrie precum și stagii de <b>pregătire</b> și recunoaștere <b>natională  și internațională.</b></Text>
                <Link to="/echipa"  style={{ textDecoration:"none", color:"inherit"}}>
                <Button>Descoperă echipa medicală</Button>
                </Link>
            </TextWrapper>
        </Wrapper>
    </Continer>
  )
}

export default AboutUs