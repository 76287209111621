import React from 'react'
import styled from 'styled-components'
import Hero from '../components/Hero';
import AboutUs from '../components/Home/AboutUs';
import TopServicii from '../components/Home/TopServicii';
import LocationDetail from '../components/Home/LocationDetail';

const Container = styled.div`
position: relative;
`;


const Home = () => {
  return (
    <Container>
      <Hero/>
      <AboutUs/>
      <TopServicii/>
      <LocationDetail/>

    </Container>
  )
}

export default Home