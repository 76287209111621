import React from 'react'
import StepForm from '../components/StepForm'

const Programare = () => {
  return (
    < >
    <StepForm />
    </>
  )
}

export default Programare