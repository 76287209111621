import React from 'react'
import styled from 'styled-components';
import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DirectionsIcon from '@mui/icons-material/Directions';
import Map from '../Map';
import { Link } from 'react-router-dom';


const Continer = styled.div`
display:flex;
margin-top: 20px;
width: 100%;
`;

const Wrapper = styled.div`
position:relative;
display: flex;
max-width: 1300px;
width:100%;
margin: 20px auto;
@media (max-width: 426px){
  
  flex-direction: column-reverse;
  gap:20px;
  
}
`;

const FirstColumn = styled.div`
width:66%;
display:flex;
flex-direction:column;
gap:30px;
@media (max-width: 426px){
  width:95%;
  margin:auto;
}
`;
const SecondColumn = styled.div`
width:34%;
flex-direction:column;
display:flex;
align-items:center;
@media (max-width: 426px){
  width:95%;
  margin:auto;
}
`;  
const PhotoBoxWrapper = styled.div`
width:100%;
height: 75svh;
display:flex;
flex-wrap:wrap;
justify-content:space-between;
@media (max-width: 426px){
display:none;
}
`;
const PhotoBox = styled.div`
width: 29%;
display:flex;
flex-direction:column;
gap:30px;
aspect-ratio : 1 / 1;
&:nth-of-type(odd){
  display:flex;
  flex-direction:column-reverse;
  
}

`;
const Photo = styled.img`
display:block;
width:100%;
height:67%;
/* margin: 0 auto 20px auto; */
&:nth-of-type(odd){
  height:33%;
  
}
`;
const MapBoxWrapper = styled.div`

width:100%;
`;
const MapBox = styled.div`
width:99%;
height:80svh;
`;
const TextWrapper = styled.div`
width:80%;
margin: 20px auto;
display:flex;
flex-direction:column;
position: sticky;
    top: 0;
    left:0;
`;
const Title = styled.h1`
margin-inline: 1rem;
width:100%;
color: #26a695;
font-style: italic;
`;
const Details = styled.h5`
margin : 0.4rem;
color:#3a84ff;
vertical-align: middle;
display:flex;
align-items: center;
margin-bottom:10px;
font-weight:600; 
font-size:1.1rem;
text-indent:15px;
`;
const ButtonWrapper = styled.div`
padding: 10px 20px;
border-radius:20px;
border: none;
color:#fff;
font-weight:600;
font-size:1.2rem;
background-color: #26a695;
cursor: pointer;
width: fit-content;
display:flex;
justify-content:center;
gap:10px;
`;

const LocationDetail = () => {
  return (
    <Continer>
      <Wrapper>
        <FirstColumn>

        {/* <PhotoBoxWrapper>
          <PhotoBox>
            <Photo/>
            <Photo/>
          </PhotoBox>
          <PhotoBox>
            <Photo/>
            <Photo/>
          </PhotoBox>
          <PhotoBox>
            <Photo/>
            <Photo/>
          </PhotoBox>
          
        </PhotoBoxWrapper> */}
        <MapBoxWrapper>
          <MapBox>
            <Map/>
          </MapBox>
        </MapBoxWrapper>
        </FirstColumn>
        <SecondColumn>
          <TextWrapper>
            <Title>Poți lua legătura cu noi la:</Title>
             <Link to="https://www.google.com/maps/dir//Ophtamax+Clinica+de+Oftalmopediatrie,+Strada+George+Bari%C8%9Biu,+Ploie%C8%99ti/@44.9426798,26.0346683,18.04z/data=!4m9!4m8!1m0!1m5!1m1!1s0x40b2498b72bb934b:0x4f612ecf5000dc51!2m2!1d26.0351751!2d44.9433065!3e0?entry=ttu" style={{textDecoration:"none", color:"inherit", }}>
            <Details><LocationOnIcon/>Strada George Barițiu, nr 4,<br/>Ploiești, Prahova<br/> (prelungire strada Praga)</Details>
            </Link>
            <Link to="tel:0745999268" style={{textDecoration:"none", color:"inherit", }}>
            <Details><CallIcon/>0745.999.268</Details>
            </Link>
            <Link to="tel:0755126393" style={{textDecoration:"none", color:"inherit", }}>
            <Details><CallIcon/>0755.126.393</Details>
            </Link>
            <Link to="mailto:office@ophtamax.ro" style={{textDecoration:"none", color:"inherit", }}>
            <Details><MailOutlineIcon/>office@ophtamax.ro</Details>
            </Link>
             <Link to="https://www.google.com/maps/dir//Ophtamax+Clinica+de+Oftalmopediatrie,+Strada+George+Bari%C8%9Biu,+Ploie%C8%99ti/@44.9426798,26.0346683,18.04z/data=!4m9!4m8!1m0!1m5!1m1!1s0x40b2498b72bb934b:0x4f612ecf5000dc51!2m2!1d26.0351751!2d44.9433065!3e0?entry=ttu" style={{textDecoration:"none", color:"inherit", display:"flex", alignSelf:"center"}}>
                <ButtonWrapper><DirectionsIcon/> Indicații de orientare</ButtonWrapper>
             </Link>
          </TextWrapper>
        </SecondColumn>
      </Wrapper>
    </Continer>
  )
}

export default LocationDetail