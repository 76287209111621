import React from 'react'
import styled from 'styled-components';
import HeroPhoto from '../img/optica_img.svg'
import DisplaySection from '../components/Optica/DisplaySection';
// import { Link } from 'react-router-dom';

const Container = styled.div`
width:100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items:center;
margin-bottom: 3rem;
`;

const Wrapper = styled.div`
display: flex;
max-width:1300px;
flex-direction:column;
/* margin-top: 25px; */
width:100%;
@media (max-width: 426px){
  /* flex-direction: column-reverse ; */
}
`;

const HeroSection = styled.div`
display: flex;
justify-content:space-between;

@media (max-width: 426px){
  flex-direction: column ;
}
`;
const HeroImgWrapper = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
width: fit-content;
@media (max-width: 426px){
    justify-content: flex-start;
    
}
`;
const HeroImg = styled.img`
max-width: 100%;
width:35svw;
display:block;
@media (max-width: 426px){
  width:100svw;
}
`;

const HeroWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-around;
margin: 0px 30px 5%;
max-width:40%;

@media (max-width: 426px){
  align-items: center;
  max-width:90%;
}

`;

const HeroText = styled.div`
color: #3a84ff;
text-indent: 5%;
font-weight: 300;
font-size:1.3rem;
font-style: italic;
@media (max-width: 426px){ 
  flex-direction: column;
}
`;



const HeroTitle = styled.h1`
font-weight: 700;
color: #26a695;
font-style: italic;
font-size: clamp(1.625rem, 1.5vw + 1rem, 2.25rem);
`;

const HeroBtn = styled.button`
background-color: #ef8a5f;
color:white;
height: 45px;
border: none;
display: flex;
align-items: center;
border-radius: 20px;
gap: 7px;
padding: 5px 15px 5px 10px;
font-weight: 600;
font-size:1.2rem;
margin-block:12px;
cursor: pointer;
`;



const Optica = () => {
  
  const handleClickScroll = () => {
    const element = document.getElementById('section-1');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Container>
      <Wrapper>

      <HeroSection>
            <HeroImgWrapper>

            <HeroImg src={HeroPhoto}/>
            </HeroImgWrapper>
            <HeroWrapper>

            <HeroTitle>Vizitează magazinul de optică</HeroTitle>
            <HeroText>Oferă-ți un plus de stil cu ajutorul produselor din <b>magazinul de optică</b> medicală aflat în incinta clinicii, aici îți oferim servicii și produse de optică medicală de cea mai bună calitate la <b>prețuri avantajoase</b></HeroText>
            
            {/* <Link to="/programare" style={{textDecoration:"none", color:"inherit"}}> */}
            
            <HeroBtn onClick={handleClickScroll}>Descoperă acum</HeroBtn>
            {/* </Link> */}
            </HeroWrapper>
           
        </HeroSection>

        
      </Wrapper>
        <DisplaySection/>
    </Container>
  )
}

export default Optica