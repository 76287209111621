import React, { useState } from 'react'

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import TipProgramare from './StepComponents/TipProgramare';
import DataProgramare from './StepComponents/DataProgramare';
import DetaliiProgramare from './StepComponents/DetaliiProgramare';
import styled from 'styled-components';
import SumarProgramare from './StepComponents/SumarProgramare';
import { StepConnector } from '@mui/material';

import emailjs from '@emailjs/browser';

const Container = styled.div`
margin-block: 5svh;

@media (max-width: 426px){
  display: flex;
  justify-content: center;
  margin-block: 3svh;
}
`;
const Wrapper = styled.div`

height:70svh;

@media (max-width: 426px){

  width: clamp(330px,50%,440px);
  height: auto;
}
`;

const TextFinalizare = styled.div`
color: #3a84ff;
text-indent: 2%;
font-weight: 300;
font-size:1.3rem;
font-style: italic;
text-align: center;
@media (max-width: 426px){ 
  flex-direction: column;
}
`;

const TitleFinalizare = styled.h1`
font-weight: 700;
color: #26a695;
font-style: italic;
text-align: center;
font-size: clamp(1.625rem, 1.5vw + 1rem, 2.25rem);
`;

const steps = [
  {
    label: 'Tip programare',
    description: ``,
  },
  {
    label: 'Cum dorești să te programezi ?',
    description:
      '',
  },
  {
    label: 'Detalii programare',
    description: ``,
  },
  {
    label: 'Sumar programare',
    description: ``,
  },
];



const StepForm = () => {

    const [activeStep, setActiveStep] = useState(0);
    const [state, setState] = useState({
      tipPrg: false,
      dataPrg: false,
      nume: '',
      telefon: '',
    });

    const handleChange = input => e => {
      setState({ ...state, [input]: e.target.value });
    };
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };

    const { tipPrg, dataPrg, nume, telefon } = state;
    const values = { tipPrg, dataPrg, nume, telefon };
    const screenSize = window.innerWidth;

     const sendEmail = (e) => {
      e.preventDefault();
     
      emailjs.sendForm('service_p78lvia', 'template_erq35n4', e.target, 'xnYJ-bJLc6VeKuXts')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };    

  return (
    
 <Container>
   <Wrapper>

    <Box sx={{ minWidth:300, }}>
    <Stepper connector={<StepConnector />} sx={{'& .MuiStepContent-root': { '@media (min-width: 426px)':{ borderLeft:"none"}}}} activeStep={activeStep} Linear alternativeLabel={screenSize > 426} orientation={screenSize > 426? 'horizontal':'vertical'} >
      {steps.map((step, index) => (
        <Step key={step.label}>
          <StepLabel
            sx={{'&  .MuiStepIcon-root.Mui-active':{ color: "#26a695" ,} ,'&  .MuiStepIcon-root.Mui-completed':{ color: "#26a695" ,} ,'& .Mui-active': {color: "#26a695", fontWeight: 700, fontSize:'1.6em'},'& .MuiStepLabel-label.Mui-active': {color:'#26a695'} }}
            
            >
            {step.label}
          </StepLabel >
          <StepContent>
          {(() => {
            switch (activeStep) {
              case 0:                 
              return (<TipProgramare
                handleChange={handleChange}
                values={values}
                />)
                case 1:                 
                return (<DataProgramare
                  handleChange={handleChange}
                  values={values}
                  />)
                  case 2:                 
                  return (<DetaliiProgramare
                    handleChange={handleChange}
                    values={values}
                    />)           
                  case 3:                 
                  return (<SumarProgramare
                    values={values}
                    />)           
                  }
                })()}
            <Box sx={{ mb: 2 }}>
              <form onSubmit={sendEmail}>
                  <input type='hidden' name='telefon' value={values.telefon} />
                  <input type='hidden' name='from_name' value={values.nume} />
                  <input type='hidden' name='tip' value={values.tipPrg} />
                  <input type='hidden' name='regim' value={values.dataPrg} />
              <div>
                <Button
                  disabled={index === 0}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                  >
                  Înapoi
                </Button>
                <Button
                  disabled={(index === 0 && !tipPrg) || (index === 1 && !dataPrg  )|| (index === 2 && (!nume || !telefon)) }
                  variant="contained"
                  onClick={handleNext}
                  sx={{ backgroundColor:"#3a84ff", mt: 1, mr: 1 }}
                  type={index === steps.length - 1 ? 'submit' : 'button'}
                  >
                  {index === steps.length - 1 ? 'TRIMITE' : 'CONTINUĂ'}
                </Button>
              </div>
                    </form>
            </Box>
          </StepContent>
        </Step>
      ))}
    </Stepper>
    {activeStep === steps.length && (
      <Paper square elevation={0} sx={{ p: 3 }}>
        <TitleFinalizare>Îți mulțumim pentru solicitarea făcută!</TitleFinalizare>
           <TextFinalizare>
            Vei fi contactat de personalul clinicii în cel mai scurt timp.
            </TextFinalizare>
        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
          RESETEAZĂ
        </Button>
      </Paper>
    )}
  </Box>
   </Wrapper>
   </Container>
  )
}

export default StepForm