import React from 'react'
import styled from 'styled-components'
import EchipaPhoto from '../img/echipa_img.png'
import { Link } from 'react-router-dom';
import StaffCard from '../components/StaffCard';


const Container = styled.div`
width:100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items:center;
margin-bottom: 3rem;
`;

const HeroSection = styled.div`
display: flex;
max-width:1300px;
margin-top: 25px;
@media (max-width: 426px){
  flex-direction: column-reverse ;
}

`;
const HeroImgWrapper = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
@media (max-width: 426px){
    justify-content: center;
    
}
`;
const HeroImg = styled.img`
max-width: 100%;
@media (max-width: 426px){
  width:95%;
}
`;

const HeroWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-around;
margin: 0px 30px;
margin-bottom:5%;
max-width:400px;

`;

const HeroText = styled.div`
color: #3a84ff;
text-indent: 5%;
font-weight: 300;
font-size:1.3rem;
font-style: italic;
@media (max-width: 426px){ 
  flex-direction: column;
}
`;

const HeroTitle = styled.h1`
font-weight: 700;
color: #26a695;
font-style: italic;
font-size: clamp(1.625rem, 1.5vw + 1rem, 2.25rem);
`;
const StickyTitle = styled.h1`
font-weight: 700;
color: #26a695;
font-style: italic;
position: sticky;
    top: 0;
    margin: 0;
    left:0;
    background-color: #fff;
    align-self: flex-start;
    @media (max-width: 426px){
  width:95%;
  z-index:2;
  width:100%;
  text-align: center;
  
}
`;
const HeroBtn = styled.button`
background-color: #ef8a5f;
color:white;
height: 45px;
border: none;
display: flex;
align-items: center;
border-radius: 20px;

gap: 7px;
padding: 5px 15px 5px 10px;
font-weight: 600;
font-size:1.2rem;
margin-block:12px;
cursor: pointer;

`;

const StaffSection = styled.div`
/* height:300px; */
width:100%;
/* background-color:#d8f6ff; */
margin-top:5rem;
display:flex;
justify-content: space-between;
position: relative;
@media (max-width: 426px){
  margin-top:4rem;
  background-color: transparent;
  flex-direction:column;
  align-items:center;
  margin-top:2rem;

}
`;

const StaffWrapper = styled.div`
max-width: 80%;
width:100%;
/* background-color: #fff; */
display:flex;
justify-content:space-evenly;
flex-wrap: wrap;

@media (max-width: 426px){
  flex-direction: column;
  justify-content:center;
  /* max-width: 300px; */
}
`;




const Echipa = () => {
  const medicalInfos = [
    {
      name: 'Dr. Oana Andrei',
      id: 0,
      category: 1,
      type: 'Medic Primar Oftalmolog',
      secondaryType: 'Medic specializat în oftalmopediatrie',
      education: '',
      experience: '',
      presentation: '',
      photo: 'https://ophtamax.ro/wp-content/uploads/2021/12/Oana-Andrei-Medic-Primar-Oftalmolog.jpg',
    },
    {
      name: 'Dr. Andreea Dăncescu',
      id: 1,
      category: 1,
      type: 'Medic Specialist Oftalmolog',
      secondaryType: 'Medic specializat în oftalmopediatrie',
      education: '',
      experience: '',
      presentation: '',
      photo: 'https://ophtamax.ro/wp-content/uploads/2021/12/Andreea-Dancescu-Gheorghe-Medic-Specialist-Oftalmolog.jpg',
    },
    {
      name: 'Dr. Irina Velcea',
      id: 2,
      category: 1,
      type: 'Medic Specialist Oftalmolog',
      secondaryType: 'Medic specializat în oftalmopediatrie',
      education: '',
      experience: '',
      presentation: '',
      photo: 'https://ophtamax.ro/wp-content/uploads/2021/12/Irina-Velcea-Medic-Primar-Oftalmolog.jpg',
    },
    {
      name: 'Dr. Georgiana Milea',
      id: 3,
      category: 1,
      type: 'Medic Specialist Oftalmolog',
      secondaryType: 'Medic specializat în oftalmopediatrie',
      education: '',
      experience: '',
      presentation: '',
      photo: 'https://ophtamax.ro/wp-content/uploads/2023/08/Georgiana-Milea-Medic-Specialist-Oftalmolog.jpg',
    },
    {
      name: 'Elena Tănase',
      id: 3,
      category: 1,
      type: 'Asistent Medical',
      secondaryType: '',
      education: '',
      experience: '',
      presentation: '',
      photo: 'https://ophtamax.ro/wp-content/uploads/2022/03/Ophtamax_portret_12032222-scaled.jpg',
    },
    
  ]

  const receptieInfos = [    
      {
        name: 'Adina Buturoiu',
        id: 4,
        category: 2,
        type: 'Secretară',
        secondaryType: '',
        education: '',
        experience: '',
        presentation: '',
        photo: 'https://ophtamax.ro/wp-content/uploads/2021/12/Adina-Buturoiu-Secretara.jpg',
      },
      {
        name: 'Cristina Plăiasu',
        id: 5,
        category: 2,
        type: 'Secretară',
        secondaryType: '',
        education: '',
        experience: '',
        presentation: '',
        photo: 'https://ophtamax.ro/wp-content/uploads/2022/03/Ophtamax_portret_33032222-scaled.jpg',
      },   
  ]
  const opticaInfos = [    
      {
        name: 'Mihaela Sora',
        id: 6,
        category: 3,
        type: 'Optometrist',
        secondaryType: '',
        education: '',
        experience: '',
        presentation: '',
        photo: 'https://ophtamax.ro/wp-content/uploads/2022/02/WhatsApp-Image-2022-02-25-at-12.26.09.jpeg',
      },
      {
        name: 'Fabiola Chirilă',
        id: 7,
        category: 3,
        type: 'Optometrist',
        secondaryType: '',
        education: '',
        experience: '',
        presentation: '',
        photo: 'https://ophtamax.ro/wp-content/uploads/2021/12/Fabiola-Chirila-Optometrist.jpg',
      },   
      {
        name: 'Andreea Iancu',
        id: 7,
        category: 3,
        type: 'Consultant Vânzări',
        secondaryType: '',
        education: '',
        experience: '',
        presentation: '',
        photo: 'https://ophtamax.ro/wp-content/uploads/2021/12/Andreea-Iancu-Consultant-Vanzari.jpg',
      },   
      {
        name: 'Simona Micu',
        id: 7,
        category: 3,
        type: 'Optometrist',
        secondaryType: '',
        education: '',
        experience: '',
        presentation: '',
        photo: 'https://ophtamax.ro/wp-content/uploads/2022/03/Ophtamax_portret_37032222-scaled.jpg',
      },   
         
  ]

  


  return (
    <Container>

      <HeroSection>
        <HeroWrapper>
          <HeroTitle>Echipă</HeroTitle>
          <HeroText>Echipa noastră medicală este formată doar din medici oftalmologi cu <b>experientă</b> și recunoaștere reală în oftalmopediatrie: <b>Dr. Oana Andrei, Dr. Andreea Dăncescu, Dr. Irina Velcea și Dr. Alina Moroianu.</b> </HeroText>
            <Link to="/programare" style={{textDecoration:"none", color:"inherit"}}>
              <HeroBtn>Programează-te</HeroBtn>
            </Link>
        </HeroWrapper>
          <HeroImgWrapper>
            <HeroImg src={EchipaPhoto}/>
          </HeroImgWrapper>
      </HeroSection>
      <StaffSection>
        <StickyTitle>PERSONAL MEDICAL</StickyTitle>
        <StaffWrapper className='Wrapper'>
          {medicalInfos.map(medicalInfo => (
      
          <StaffCard key={medicalInfo.name} staffInfo={medicalInfo} />
          ))}
          
        </StaffWrapper>
      </StaffSection>
      <StaffSection>
        <StickyTitle>RECEPȚIE</StickyTitle>
        <StaffWrapper>
          {receptieInfos.map(receptieInfo => (
      
          <StaffCard key={receptieInfo.name} staffInfo={receptieInfo} />
          ))}          
        </StaffWrapper>
      </StaffSection>
      <StaffSection>
        <StickyTitle>OPTICĂ</StickyTitle>
        <StaffWrapper>
          {opticaInfos.map(opticaInfo => (
      
          <StaffCard key={opticaInfo.name} staffInfo={opticaInfo} />
          ))}          
        </StaffWrapper>
      </StaffSection>
    </Container>
  )
}

export default Echipa