import { Box, FormControl } from '@mui/material'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

const SumarProgramare = (props) => {

    const { values } = props; 

  return (
    <Box sx={{ display: 'flex'}}>
      <FormControl 
    //    required
       component="fieldset"
       sx={{ m: 3 }}
       variant="standard"
      //  error
      >

<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          <ListItem disablePadding>
              <ListItemIcon sx={{color:'#26a695',}}>
                <FormatListNumberedIcon/>
              </ListItemIcon>
              <ListItemText sx={{'&  .MuiTypography-root.MuiListItemText-secondary':{ color: "#3a84ff" , fontStyle:"italic"} , color:'#3a84ff',}} primary="Tip programare:" secondary={values.tipPrg} />
          
          </ListItem>
          <ListItem disablePadding>
              <ListItemIcon sx={{color:'#26a695',}}>
                <DateRangeIcon/>
              </ListItemIcon>
              <ListItemText sx={{'&  .MuiTypography-root.MuiListItemText-secondary':{ color: "#3a84ff" , fontStyle:"italic"} ,color:'#3a84ff',}} primary="Dată programare" secondary={values.dataPrg}/>
          </ListItem>
          <ListItem disablePadding>
              <ListItemIcon sx={{color:'#26a695',}}>
                <AccountBoxIcon/>
              </ListItemIcon>
              <ListItemText sx={{'&  .MuiTypography-root.MuiListItemText-secondary':{ color: "#3a84ff" , fontStyle:"italic"} ,color:'#3a84ff',}} primary="Nume" secondary={values.nume}/>
          </ListItem>
          <ListItem disablePadding>
              <ListItemIcon sx={{color:'#26a695',}}>
                <LocalPhoneIcon/>
              </ListItemIcon>
              <ListItemText sx={{'&  .MuiTypography-root.MuiListItemText-secondary':{ color: "#3a84ff" , fontStyle:"italic"} ,color:'#3a84ff',}} primary="Telefon" secondary={values.telefon} />
          </ListItem>
        </List>
        </FormControl>
    </Box>
  )
}

export default SumarProgramare