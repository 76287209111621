import React from 'react'
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Radio, RadioGroup } from '@mui/material';


const TipProgramare = (props) => {

    const { handleChange, values } = props; 
    
    return (
    <Box sx={{ display: 'flex' }}>
      <FormControl 
    //    required
    //    component="fieldset"
       sx={{ m: 3 }}
       variant="standard"
      >
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={values.tipPrg}
        onChange={handleChange('tipPrg')}
        required
      >
        <FormControlLabel sx={{color:'#3a84ff', fontStyle:'italic' }} value="Consultație" control={<Radio />} label="Consultație" />
        <FormControlLabel sx={{color:'#3a84ff', fontStyle:'italic' }} value="Control" control={<Radio />} label="Control" />
        <FormControlLabel sx={{color:'#3a84ff', fontStyle:'italic' }} value="Investigații" control={<Radio />} label="Investigații" />
      </RadioGroup>
      </FormControl>
    </Box>
  )
}

export default TipProgramare