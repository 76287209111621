import { Box, FormControl, FormLabel, TextField } from '@mui/material'
import React, { useState } from 'react'
import { MuiTelInput } from 'mui-tel-input'


const DetaliiProgramare = (props) => {

  const { handleChange, values } = props; 
  const [tel, setTel] = useState(values.telefon)

  const handleTel = (newValue) => {
    setTel(newValue)    
  }

  return (
    <Box sx={{ display: 'flex'}}>
      <FormControl 
      //  required
       component="fieldset"
       sx={{ m: 3 }}
       variant="standard"
      //  error
      >
      <FormLabel sx={{color:'#3a84ff', fontStyle:'italic' }} component="legend">Te rugăm să completezi datele de contact.</FormLabel>
    <TextField 
              placeholder="Completeaza numele tau"
              label="Nume"
              onChange={handleChange('nume')}
              defaultValue={values.nume}
              margin="normal"
             sx={{borderColor: "#3a84ff"}}
             />
            <br/>
            <MuiTelInput 
              placeholder="Completeaza numarul de telefon"
              label={values.telefon || "Telefon"}
              defaultCountry="RO"
              onChange={handleTel}
              value={tel}
              onInput={handleChange('telefon')}
              margin="normal"
              sx={{ '&.Mui-focused fieldset': {
                borderColor: 'green'}}}
              // size="small"
              // fullWidth
              // required
            />
            </FormControl></Box>
  )
}

export default DetaliiProgramare