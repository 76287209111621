import React from 'react'
import styled from 'styled-components'
import LogoPediatrie from '../img/oftalmopediatrie_logo.png'
import LogoOphtamax from '../img/ophtamax_logo.png'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Link } from 'react-router-dom';



const Container = styled.div`
background-color: #f9f9f9;
width:100%;

`;
const Wrapper = styled.div`
display:flex;
justify-content:space-evenly;
align-items: center;
max-width:1300px;
margin:0 auto;
padding: 30px 0;

@media (max-width: 426px){
    flex-direction:column;
    align-items: flex-start;
    max-width: 300px;
}
`;
const LogoIconWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 15px;

@media (max-width: 426px){
    margin-inline: auto;
}
`;
const Icons = styled.div`
display:flex;
justify-content:space-between;
align-items: center;
@media (max-width: 426px){
margin-bottom: 15px;
}
`;
const Icon = styled.div`
background-color: #dfebf3;
color: #3a84ff;
border-radius:50%;
display: flex;
align-items:center;
padding:5px;
`;
const LogoPediatrieImg = styled.img`
height: 60px;
`;
const LogoOphtamaxImg = styled.img`
height: 80px;
@media (max-width: 426px){
margin-inline: auto;
}

`;
const InfoLinks = styled.ul`
/* width:50%; */

`;
const InfoLinksTitle = styled.div`
font-weight:600;
font-size:1.6rem;
`;
const InfoLink = styled.li`
font-weight:400;
font-size:1.1rem;
/* list-style: none; */
position: relative;
/* margin: 5px 0px; */

&::before{
    content: "";
    background: #000;
    width: 14px;
    height:2px;
    /* margin-right:15px; */
    position: absolute;
    top:50%;
    left:-30px;


}

`;



const Footer = () => {
  return (
    <Container>
        <Wrapper>
            <LogoIconWrapper>
                <Link to="/" style={{textDecoration:"none", color:"inherit"}}>
                <LogoPediatrieImg src={LogoPediatrie}/>
                </Link>
                <Icons>
                <Link to="https://wa.me/40745999268" style={{textDecoration:"none", color:"inherit"}}>

                <Icon>
                    <WhatsAppIcon/>
                </Icon>
                </Link>
                <Link to="tel:0745999268" style={{textDecoration:"none", color:"inherit"}}>
                <Icon>
                    <CallIcon/>
                </Icon>
                </Link>
                <Link to="https://www.google.com/maps/dir//Ophtamax+Clinica+de+Oftalmopediatrie,+Strada+George+Bari%C8%9Biu,+Ploie%C8%99ti/@44.9426798,26.0346683,18.04z/data=!4m9!4m8!1m0!1m5!1m1!1s0x40b2498b72bb934b:0x4f612ecf5000dc51!2m2!1d26.0351751!2d44.9433065!3e0?entry=ttu" style={{textDecoration:"none", color:"inherit"}}>
                <Icon>
                    <LocationOnIcon/>
                </Icon>
                </Link>
                <Link to="https://www.facebook.com/ophtamax/" style={{textDecoration:"none", color:"inherit"}}>
                <Icon>
                    <FacebookIcon/>
                </Icon>
                </Link>
                </Icons>
            </LogoIconWrapper>
            {/* <InfoLinksTitle>Servicii

            <InfoLinks>                
                <InfoLink>Consultatii</InfoLink>
                <InfoLink>Consultatii</InfoLink>
                <InfoLink>Consultatii</InfoLink>
                <InfoLink>Consultatii</InfoLink>
            </InfoLinks>
            </InfoLinksTitle>
            <InfoLinksTitle>Linkuri Utile

            <InfoLinks>                
                <InfoLink>Drepturi Pacienți</InfoLink>
                <InfoLink>Obligații Pacienți</InfoLink>
                <InfoLink>Feedback Pacienți</InfoLink>
                <InfoLink>Informații</InfoLink>
            </InfoLinks>
            </InfoLinksTitle> */}
            <Link to="https://www.ophtamax.ro" style={{ textDecoration:"none", color:"inherit"}}>
            <LogoOphtamaxImg  src={LogoOphtamax}/>
            </Link>
        </Wrapper>
    </Container>
  )
}

export default Footer