import React from 'react'
import styled from 'styled-components'
import IconConsultatii from '../../img/icon_consultatii.svg'
import IconInvestigatii from '../../img/icon_investigatii.svg'
import IconPrescriptie from '../../img/icon_prescriptie.svg'
import IconOptica from '../../img/icon_optica.svg'
import IconGravura from '../../img/icon_gravura.svg'
import IconAtelier from '../../img/icon_atelier.svg'

const Continer = styled.div`
display:flex;
margin-block: 20px;
background-color:#d8f6ff;
`;

const Wrapper = styled.div`
display: flex;
max-width: 1300px;
margin: auto;
flex-direction: column;
width:100%;
`;
const Title = styled.h2`
font-weight: 700;
color: #26a695;
font-style: italic;
align-self:center;

@media (max-width: 426px){

width:90%;
margin: auto;
margin-bottom: 1.9rem;

}
`;
const Cards = styled.div`
display: flex;
width:100%;
/* flex-direction:column; */
justify-content:space-evenly;
/* align-items:center; */
flex-wrap: wrap;
@media (max-width: 426px){

width:90%;
margin: auto;
}
`;
const CardWrapper = styled.div`
display: flex;
flex-direction:column;
/* justify-content:center; */
align-items:center;
flex: 0 0 33.3%;
margin-bottom: 1.9rem;
@media (max-width: 426px){
    
    
    flex: 0 0 50%;
    margin: auto;
    margin-bottom: 1.9rem;
}
`;

const CardIcon = styled.img`
height:64px;
width:64px;
`;

const NumeServiciu = styled.h3`
display: flex;
font-weight: 700;
margin : 0.3rem;
color: #26a695;
font-style: italic;
text-align: center;

`;
const ShortDesc = styled.h5`
margin : 0.3rem;
color: #3a84ff;

`;

const TopServicii = () => {
  return (
    <Continer>
        <Wrapper>
            <Title>Servicii de care poți beneficia în clinica noastră</Title>
            <Cards>

            <CardWrapper>
                <CardIcon src={IconConsultatii}/>
                <NumeServiciu>Consultații complete oftalmopediatrie</NumeServiciu>
                {/* <ShortDesc>Lorem ipsum este textul standard</ShortDesc> */}
            </CardWrapper>
            <CardWrapper>
                <CardIcon src={IconInvestigatii}/>
                <NumeServiciu>Investigații oftalmologice</NumeServiciu>
                {/* <ShortDesc>Lorem ipsum este textul standard</ShortDesc> */}
            </CardWrapper>
            <CardWrapper>
                <CardIcon src={IconPrescriptie}/>
                <NumeServiciu>Prescripție ochelari</NumeServiciu>
                {/* <ShortDesc>Lorem ipsum este textul standard</ShortDesc> */}
            </CardWrapper>
            <CardWrapper>
                <CardIcon src={IconOptica}/>
                <NumeServiciu>Magazin Optică</NumeServiciu>
                {/* <ShortDesc>Lorem ipsum este textul standard</ShortDesc> */}
            </CardWrapper>
            <CardWrapper>
                <CardIcon src={IconAtelier}/>
                <NumeServiciu>Atelier propriu de montaj ochelari</NumeServiciu>
                {/* <ShortDesc>Lorem ipsum este textul standard</ShortDesc> */}
            </CardWrapper>
            <CardWrapper>
                <CardIcon src={IconGravura}/>
                <NumeServiciu>Personalizare lentile*</NumeServiciu>
                <ShortDesc>*Solicită mai multe informații în magazin</ShortDesc>
            </CardWrapper>
            </Cards>
            
        </Wrapper>
    </Continer>
  )
}

export default TopServicii