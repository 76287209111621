import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components'

const Btn = styled.div`
width:fit-content;
height:fit-content;
display:flex;
padding:1rem;
background-color: #26a695;
text-orientation: mixed;
writing-mode: vertical-rl;
color: #fff;
position: fixed;
top: 50%;
right:0;
z-index: 11;
font-weight: 600;
font-size:1.2rem;
cursor: pointer;
border-radius: 20px 0px 0px 20px;
opacity: 0;

transition: 0.4s ease-in-out;

@media (max-width: 426px){
  top:70%;
}
`;

const Lable = styled.div`
display:flex;
flex-direction: column;
align-items: flex-end;
padding:1rem;
background-color: #26a695;
color: #fff;
position: fixed;
top: 50%;
right:0;
z-index: 15;
font-weight: 600;
font-size:1.2rem;
cursor: pointer;
border-radius: 20px 0px 0px 20px;
@media (max-width: 426px){
  top:70%;
}


`;

const StickyBtn = () => {

    const [btnIsClicked, setBtnIsClicked] = useState(false);

const handleOnClick = () =>{
    setBtnIsClicked(!btnIsClicked);
}

  return (
  <>
    <Lable style={btnIsClicked ? {display: 'flex',transition: '0.8s ease-in-out'}: {display: 'none'}} >
        <div onClick={handleOnClick} style={{color:'#000'}}>x</div>
        Pentru Urgențe Medicale
    {/* <br/> */}
    <Link to="tel:0745999268" style={{ textDecoration:"none", color:"inherit", fontSize: "1.2em", fontWeight:"700"}}>0745.999.268</Link>
    </Lable>
      <Btn style={btnIsClicked ? {display: 'none', opacity:0, transition: '0.8s ease-in-out'}: { opacity:1, transition: '0.8s ease-in-out' }} onClick={handleOnClick}>Urgențe medicale</Btn>
  </>
  
  )
}

export default StickyBtn