import React from 'react'
import styled from 'styled-components';
import HeroPhoto from '../img/contact_img.png'
import { Link } from 'react-router-dom';
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import "leaflet/dist/leaflet.css";
import Map from '../components/Map';

const Container = styled.div`
width:100%;
display: flex;
flex-direction:column;
justify-content: center;
align-items:center;
overflow: hidden;
`;

const HeroSection = styled.div`
display: flex;
/* max-height:50svh; */
margin-top: 25px;

/* margin: 0 auto; */

@media (max-width: 426px){
  flex-direction: column-reverse ;
}

`;
const HeroImgWrapper = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
width: fit-content;
@media (max-width: 426px){
    justify-content: flex-start;
    
}
`;
const HeroImg = styled.img`
max-width: 100%;
@media (max-width: 426px){
  width:100%;
}
`;

const HeroWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
margin: 0px 30px 5%;
/* margin-bottom:5%; */
/* max-width:400px; */

@media (max-width: 426px){
 align-items: flex-start;
}

`;

const HeroText = styled.div`
color: #3a84ff;
font-weight: 500;
/* font-style: italic; */
display: flex;
min-width: 50svw;
@media (max-width: 426px){ 
  flex-direction: column;
}
`;

const TextWrapper = styled.div`
width: 70%;
display: flex;
flex-direction: column;
/* display: flex; */
justify-content: flex-start;
gap: 10px;
@media (max-width: 426px)
{ width: 100%;};
& ul.left{
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 1rem;
  @media (max-width: 426px){ 
    align-items: flex-start;
  };
};
`;

const TextTitle = styled.div`
font-weight: 700;
font-size: 1.6rem;
`;
const Text = styled.div`
font-size: 1.2rem;
display: flex;
align-items:center;
gap: 10px;

`;

const HeroTitle = styled.h1`
font-weight: 700;
color: #26a695;
font-style: italic;


`;

const HeroBtn = styled.button`
background-color: #ef8a5f;
color:white;
height: 45px;
border: none;
display: flex;
align-items: center;
border-radius: 20px;

gap: 7px;
padding: 5px 15px 5px 10px;
font-weight: 600;
font-size:1.2rem;
margin-block:12px;
cursor: pointer;
`;

const MapContainer = styled.div`
background-color: #d8f6ff;
width:100%;
display:flex;
flex-direction:column;
align-items:center;
gap:1rem;
padding-bottom: 5rem;

position: relative;
display: flex;
flex-direction: column;
align-items: center;
/* min-height: 400px; */
/* padding-top: 100px; */
`;
const MapWrapper = styled.div`
height: 70svh;
  width: 80svw;
`;

const MapAdressTitle = styled.h1`
font-weight: 700;
color: #26a695;
font-style: italic;

@media (max-width: 426px){ 
  margin: 0 10px;
  font-size: 1.4rem; };

`;

const Section = styled.div`
height:10svh;
z-index:-1;
`;

const WaveBorderBottom = styled.div`
 position: absolute;
    height: 150px;
    width: 100%;
    bottom: 0;
    text-align: center;
    /* overflow: hidden; */
&::before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 55%;
    height: 100%;
    transform: translate(85%, 60%);
    background-color: #fff;
    overflow: hidden;
}
&::after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 55%;
    height: 100%;
    background-color: #d8f6ff;
    transform: translate(-4%, 40%);
    z-index: -1;
}
`;
const WaveBorderTop = styled.div`
 position: absolute;
    height: 250px;
    width: 100%;
    top: -30%;
    text-align: center;
    overflow: hidden;
  @media (max-width: 426px){
 display: none;
}

&::after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 55%;
    height: 100%;
    background-color: #d8f6ff;
    transform: translate(45%, 60%);
    z-index: -1;
}
&::before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 55%;
    height: 100%;
    transform: translate(95%, 20%);
    background-color: #ecf9ff;
    z-index:-1;
}
`;


const Contact = () => {
  return (
    <Container>

      <HeroSection>
            <HeroImgWrapper>

            <HeroImg src={HeroPhoto}/>
            </HeroImgWrapper>
            <HeroWrapper>
            <HeroTitle>Contacteaza-ne</HeroTitle>
            <HeroText>

              <TextWrapper>
                <TextTitle>PROGRAM</TextTitle>
                  <Text>
                      Luni – Joi : 10:00 – 18:00
                  </Text>
                <Text>
                    Vineri : 09:00 – 17:00
                </Text>
                <Text>
                    Sâmbătă : Închis
                </Text>
                <Text>
                  Duminică : Închis
                </Text>
              </TextWrapper>
              <TextWrapper>
                <TextTitle>&nbsp;</TextTitle>
                <Link to="tel:0344141423" style={{textDecoration:"none", color:"inherit", display:"flex", alignItems:"center"}}>
                  <Text>
                    <CallIcon/> 0344.141.423
                  </Text>
                </Link>
              
                <Link to="tel:0745999268" style={{textDecoration:"none", color:"inherit", display:"flex", alignItems:"center"}}>
                  <Text>
                  <CallIcon/> 0745.999.268
                  </Text>                 
                </Link>
                <Link to="tel:0755126393" style={{textDecoration:"none", color:"inherit", display:"flex", alignItems:"center"}}>
                  <Text>
                    <CallIcon/> 0755.126.393
                  </Text>
                </Link>
                <Link to="mailto:office@ophtamax.ro" style={{textDecoration:"none", color:"inherit", display:"flex", alignItems:"center"}}>
                  <Text><MailOutlineIcon/> office@ophtamax.ro</Text>
                </Link>
              </TextWrapper>
              
            </HeroText>
            <Link to="/programare" style={{textDecoration:"none", color:"inherit"}}>            
              {/* <HeroBtn>Programeaza-te</HeroBtn> */}
            </Link>
          </HeroWrapper>
           
      </HeroSection>
     
      <MapContainer>
        <WaveBorderTop id='top'/>
        <MapAdressTitle>Strada George Barițiu, nr 4, Ploiești, Prahova (prelungire strada Praga)</MapAdressTitle>
        <MapWrapper>
          
          <Map/>
        </MapWrapper>
          {/* <WaveBorderBottom/> */}
      </MapContainer>
          {/* <Section>Hello</Section> */}
    </Container>
    
        
            
  )
}

export default Contact