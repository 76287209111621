import { BrowserRouter, Routes, Route} from 'react-router-dom'
import './App.css';
import styled from 'styled-components'
import Home from './pages/Home';
import Echipa from './pages/Echipa';
import Optica from './pages/Optica';
import Preturi from './pages/Preturi';
import Servicii from './pages/Servicii';
import Contact from './pages/Contact';
import Programare from './pages/Programare';
import Footer from './components/Footer';
import Header from './components/Header';
import StickyBtn from './components/StickyBtn';

const Container = styled.div`
display: flex;
/* height:100%; */
width:100%;

`;
const Wrapper = styled.div``;
const Main = styled.div`
/* flex: 7; */
display:flex;
flex-direction:column;
position: relative;
width:100%;
`;

function App() {


  return (
    <Container>
      <BrowserRouter>
        <Main>
          <Header/>
          <StickyBtn/>
          <Wrapper>
            <Routes>
              <Route path='/'>
                <Route index element={<Home/>} />
                <Route path='/echipa' element={<Echipa/>} />
                <Route path='/optica' element={<Optica/>} />
                <Route path='/preturi' element={<Preturi/>} />
                <Route path='/servicii' element={<Servicii/>} />
                <Route path='/contact' element={<Contact/>} />
                <Route path='/programare' element={<Programare/>} />
              </Route>
            </Routes>
          </Wrapper>
        <Footer/>
        </Main>
      </BrowserRouter>
    </Container>   
  );
}

export default App;
