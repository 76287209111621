import React from 'react'
import styled from 'styled-components'
import AvatarOana from '../img/Oana-Andrei-Avatar3.png'
import AvatarAndreea from '../img/Andreea-Dancescu-Avatar.png'
import AvatarVelcea from '../img/Irina-Velcea-Avatar.png'
import AvatarDef from '../img/Irina-Velcea-Avatar.png'


const Container = styled.div`
display:flex;
flex: 0 0 33.333333%;
width: 292px;
cursor: pointer;
width:100%;
justify-content: space-evenly;
margin-bottom: 5rem;
/* flex-direction: column; */

background: linear-gradient(0deg, #d8f6ff 70%, transparent 30%); 
/* background-color:#d8f6ff; */
@media (max-width: 426px){
    margin-top: 50px;
    
    /* box-shadow: 4px 4px 8px 0 rgba(0,0,0,0.2); */
    &:nth-of-type(2n) {
        background-color: #fff;
        margin-top: 50px;

        
 }

}


`;
const Wrapper = styled.div`
    margin-bottom: 50px;
@media (max-width: 426px){
    width:100%;
    /* margin-bottom: 100px; */


}
`;

const PhotoWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;

`;


const FlipWrapper = styled.div`
@media (max-width:426px) {
    width: 150px; 
    height: 100px;
    
  }

`;
const FlipInner = styled.div`
  width: 100%;
  height: 100%;
  


`;

const DocPhotoFront = styled.img`
width:250px;
/* border: #26a695 solid 10px; */
/* border-radius:50%; */
/* background-color: #000; */
display:flex;
align-items:center;
justify-content:center;

@media (max-width: 426px){
    width:150px;
}
`;
const DocPhotoBack = styled.img`
width:250px;
/* border: #26a695 solid 10px; */
/* border-radius:50%; */
/* background-color: #000; */
display:flex;
align-items:center;
justify-content:center;

@media (max-width: 426px){
    width:150px;
}
`;

const FlipFront = styled.div`
border: #26a695 solid 10px;
border-radius:50%;
overflow: hidden;
height:250px;
width:250px;
/* margin-top:-125px; */
@media (max-width: 426px){
    /* margin-top:-75px; */
    width:150px;
    height:150px;
   
}
`;
const FlipBack = styled.div`
border: #26a695 solid 10px;
border-radius:50%;
overflow: hidden;
height:250px;
width:250px;
/* margin-top:-125px; */
@media (max-width: 426px){
    /* margin-top:-75px; */
    width:150px;
    height:150px;
}
`;

const DoctorName = styled.h1`
color:#26a695;
font-size: 1.4rem;
@media (max-width: 426px){
width:90%;
margin: auto;
}


`;
const DoctorType = styled.div`
color: #3a84ff;
font-weight: 500;
font-size: 1rem;
@media (max-width: 426px){
width:90%;
margin: auto;
}

`;

const DetailWrapper = styled.div`
margin-top:8rem;

@media (max-width: 426px) {
    margin-top:5rem;

}
`;


const StaffCard = ( {staffInfo}) => {
  return (
    <Container>
        <Wrapper>
            <PhotoWrapper>
                <FlipWrapper className='flipWrapper'>

                <FlipInner className='flipInner'>

                <FlipFront className='flipFront'>
            <DocPhotoFront src={staffInfo.photo}/>

                </FlipFront >
                <FlipBack className='flipBack'>
            <DocPhotoBack src={(() => {
            switch (staffInfo.id) {
              case 0:                 
              return AvatarOana
              case 1:                 
              return AvatarAndreea              
              case 2:                 
              return AvatarVelcea
              default:
                return AvatarDef                          
                             
                  }
                })()}/>

                </FlipBack>
                </FlipInner>
                </FlipWrapper>
            </PhotoWrapper>
            <DetailWrapper>

            <DoctorName>{staffInfo.name}</DoctorName>
            <DoctorType>{staffInfo.type}</DoctorType>
            <DoctorType>{staffInfo.secondaryType}</DoctorType>
            </DetailWrapper>

            
        </Wrapper>
    </Container>
  )
}

export default StaffCard