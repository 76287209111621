import React from 'react'
import styled from 'styled-components';
import HeroPhoto from '../img/hero_img.png'
import { Link } from 'react-router-dom';


const Container = styled.div`
width:100%;
display: flex;
justify-content: center;
align-items:center;
`;

const HeroSection = styled.div`
display: flex;
max-width:1300px;
margin-top: 20px;

/* margin: 0 auto; */

@media (max-width: 426px){
  flex-direction: column-reverse ;
}

`;
const HeroImgWrapper = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
@media (max-width: 426px){
    justify-content: center;
    
}
`;
const HeroImg = styled.img`
max-width: 95%;
@media (max-width: 426px){
  width:95%;
}
`;

const HeroWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-around;
margin: 0px 30px;
margin-bottom:5%;
max-width:400px;

`;

const HeroText = styled.div`
color: #3a84ff;
text-indent: 5%;
font-weight: 300;
font-size:1.3rem;
font-style: italic;
@media (max-width: 426px){ 
  flex-direction: column;
}
`;

const HeroTitle = styled.h1`
font-weight: 700;
color: #26a695;
font-style: italic;
font-size: clamp(1.625rem, 1.5vw + 1rem, 2.25rem);
`;
const HeroBtn = styled.button`
background-color: #ef8a5f;
color:white;
height: 45px;
border: none;
display: flex;
align-items: center;
border-radius: 20px;

gap: 7px;
padding: 5px 15px 5px 10px;
font-weight: 600;
font-size:1.2rem;
margin-block:12px;
cursor: pointer;

`;

const Hero = () => {
  return (
    <Container>

    <HeroSection>
            <HeroWrapper>

            <HeroTitle>Ophtamax Clinica de Oftalmopediatrie</HeroTitle>
            <HeroText>Clinica de <b>oftalmopediatrie</b> Ophtamax este singura și cea mai titrată clinică destinată oftalmolopediatriei din județul Prahova. Oferim consultații și investigații complete de oftalmopediatrie <b>copiilor</b> de toate vârstele (inclusiv nou-născuți și sugari).</HeroText>
            <Link to="/programare" style={{textDecoration:"none", color:"inherit"}}>
            
            <HeroBtn>Programează-te</HeroBtn>
            </Link>
            </HeroWrapper>
            <HeroImgWrapper>

            <HeroImg src={HeroPhoto}/>
            </HeroImgWrapper>
        </HeroSection>
    </Container>
  )
}

export default Hero