import React from 'react'
import { MapContainer, Marker, Popup, TileLayer, Tooltip,  } from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import { Icon } from 'leaflet';
import { Link } from 'react-router-dom';
import DirectionsIcon from '@mui/icons-material/Directions';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
padding: 10px 20px;
border-radius:20px;
border: none;
color:#fff;
font-weight:600;
font-size:1.2rem;
background-color: #26a695;
cursor: pointer;
width: fit-content;
display:flex;
justify-content:center;
gap:10px;
position: sticky
`;

const locationIcon = new Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/512/3448/3448513.png",
    iconSize: [50,50]
})
const Map = () => {
  return (
    <MapContainer center={[44.94332,26.0354]} zoom={17} scrollWheelZoom={false}>
    <TileLayer
      // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
   
    <Marker position={[44.94337,26.03522]} icon={locationIcon}>
    <Tooltip direction="top" offset={[0, -20]} opacity={2} permanent>
        CLINICA DE OFTALMOPEDIATRIE OPHTAMAX
      </Tooltip>
      <Popup>
      <Link to="https://www.google.com/maps/dir//Ophtamax+Clinica+de+Oftalmopediatrie,+Strada+George+Bari%C8%9Biu,+Ploie%C8%99ti/@44.9426798,26.0346683,18.04z/data=!4m9!4m8!1m0!1m5!1m1!1s0x40b2498b72bb934b:0x4f612ecf5000dc51!2m2!1d26.0351751!2d44.9433065!3e0?entry=ttu" style={{textDecoration:"none", color:"inherit", display:"flex", alignSelf:"center"}}>
                <ButtonWrapper><DirectionsIcon/> Indicatii de orientare</ButtonWrapper>
             </Link>
      </Popup>
    </Marker>
  </MapContainer>
  )
}

export default Map