import React from 'react'
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Radio, RadioGroup } from '@mui/material';
import Typography from '@mui/material/Typography';



const DataProgramare = (props) => {
    const { handleChange, values } = props; 
    
    return (
    <Box sx={{ display: 'flex' }}>
      <FormControl 
    //    required
    //    component="fieldset"
       sx={{ m: 3 }}
       variant="standard"
      >
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={values.dataPrg}
        onChange={handleChange('dataPrg')}
      >
        <FormControlLabel sx={{color:'#3a84ff', fontStyle:'italic' }} value="Următorul loc disponibil" option control={<Radio />} label="Următorul loc disponibil" />
        <Typography sx={{color:'#3a84ff' }} variant="caption">Vei fi contactat în cel mai scurt timp pentru a fi informat de următoarele locuri disponibile.</Typography>
        <FormControlLabel sx={{color:'#3a84ff', fontStyle:'italic' }} value="Programare prioritară" control={<Radio />} label="Programare prioritară" />
        <Typography sx={{color:'#3a84ff' }} variant="caption">Vei fi contactat în următoarele 5 minute și vom stabilii împreună medicul și tipul programării. Tariful pentru programarea in regim prioritar este de 450 RON</Typography>
      </RadioGroup>
      </FormControl>
    </Box>
  )
}

export default DataProgramare